import React from "react";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";

export default function WalletButton({
  provider,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  selectedAddress,
}) {
  const handleClick = () => {
    if (!provider) {
      loadWeb3Modal();
    } else {
      logoutOfWeb3Modal();
    }
  };

  const message = !provider ? "Connect" : selectedAddress;

  return (
    <Button
      style={{
        backgroundColor: "rgba(217, 171, 249, 0.4)",
        color: "white",
        borderRadius: "14px",
        // fontSize: "14px",
        padding: "0.5%",
        lineHeight: "1.2",
        fontWeight: "50",
        // width: "14%",
      }}
      onClick={handleClick}
    >
      {message}
    </Button>
    // <Chip
    //   style={{
    //     color: "pink",
    //     borderRadius: "24px",
    //     fontSize: "24px",
    //     padding: "1%",
    //     lineHeight: "1.2",
    //     fontWeight: "200",
    //     width: "24%",
    //   }}
    //   onClick={handleClick}
    //   label={message}
    // />
  );
}
