import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Transactions from "./Transactions";
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from "date-fns";
import WithdrawButton from './buttons/WithdrawButton';
import { isAddress } from "../../../utils";
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Slider from '@material-ui/core/Slider';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "50%",
    padding: "1 rem",
    marginTop: "40px"
  },
  centered: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxWidth: "95%",
    ["@media (max-width:600px)"]: { maxWidth: "100%", paddingLeft: 0, paddingRight: 0 },
  },
  gridTable: {
    flexGrow: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function DAppV2({ balanceV2, onUpdateRewardToken, balanceBnb, dividendWallet, dividendQueue, dividendWithdrawable, dividendTotal, currentToken, currentTokenAddress, transactions, revertToBnb, dividendLastClaimTime, withdrawRewards, taxFreeAmount, buyBackAdMonkey }) {

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isInputError, setInputError] = useState(false);
  const [buyBackAmountValue, setBuyBackAmountValue] = useState(0);
  const [sliderValue, setSliderValue] = useState(0)
  const [sliderInputValue, setSliderInputValue] = useState(0);

  const componentRef = React.useRef();

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const classes = useStyles();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!isAddress(evt.target.tokenAddress.value)) {
      setInputError(true);
      return;
    }

    handleToggle();

    let newRewardToken = evt.target.tokenAddress.value;

    if (newRewardToken === "0x72be291e63bdd6da1b9db08fe4a2b928539fbdd9") {
      setErrorMessage('Can not set reward token as AdMonkey!');
      handleClose();
      return;
    }

    onUpdateRewardToken(newRewardToken);

    handleClose();

    evt.target.tokenAddress.value = "";
  }

  const handleSubmitBnbBuyback = (evt) => {
    evt.preventDefault();

    let buybackAmount = evt.target.buybackAmount.value;

    buyBackAdMonkey(buybackAmount);

  }

  const handleBuyBackBnb = (e) => {
    alert(`Buy back ${e}`);
    return;
    e.preventDefault();

  }

  const handleRevertToBnb = e => {
    e.preventDefault();

    revertToBnb();
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setSliderInputValue(Number(newValue / 100) * taxFreeAmount / 10 ** 18);
  };

  const AlteredTaxFreeAmount = Number(taxFreeAmount / 10 ** 18).toFixed(18) + ' BNB';

  const wholeTaxFreeAmount = taxFreeAmount

  function valuetext(value) {
    return `${value / 10 ** 18 } BNB`;
  }

  const SubmitButton = () => (
    <IconButton type="submit"><SendIcon /></IconButton>
  );

  function populateBuybackInput(amount) {

    setBuyBackAmountValue(amount);

  }

  const withdrawable = dividendWithdrawable;

  const onHandleFocusInput = () => {
    setInputError(false);
  };

  const [selectorCa, setSelectorCa] = React.useState('');

  const handleSelectorChange = (event) => {
    setSelectorCa(event.target.value);
  }

  const onCaAddressChange = (event) => {
    setSelectorCa(event.target.value);
  };

  return (

    <Container className={classes.container}>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.gridTable}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4" style={{ textAlign: "center", marginTop: 15, marginBottom: 15, fontWeight: 100 }}>
            Welcome to the AdMonkey V2 dApp
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} style={{ padding: 20 }}>
          <Paper variant="outlined" style={{ marginTop: 10, marginBottom: 10, padding: 20, borderColor: "#fcc400" }}>
            <Typography variant="h5">Current reward:
              <Chip label={currentToken} style={{ backgroundColor: "#ffc20d", display: "block", textAlign: "center", lineHeight: "32px", fontSize: "18px" }} fullWidth /></Typography>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <TextField
                  id="standard-full-width"
                  label="Enter Token Address"
                  style={{ marginTop: 25, marginBottom: 25 }}
                  placeholder="Token Address"
                  margin="normal"
                  name="tokenAddress"
                  error={isInputError}
                  helperText={isInputError ? "Invalid address" : ""}
                  fullWidth
                  onFocus={onHandleFocusInput}
                  InputProps={{ endAdornment: <SubmitButton /> }}
                  value={selectorCa}
                  onChange={onCaAddressChange}
                />
                
              </FormControl>
              <span style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</span>
              <p>Choose one of our featured tokens</p>
              <FormControl fullWidth>
                <Select
                  labelId="predefined-selector-label"
                  id="predefined-selector"
                  value={selectorCa}
                  label="Top Tokens"
                  onChange={handleSelectorChange}
                  helperText="Top Tokens"
                >
                  <MenuItem value="" selected>-- Clear --</MenuItem>
                  <MenuItem value="0xBB994E80E2eDc45dCe9065bda73ADc7E9337b64F"><img src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xBB994E80E2eDc45dCe9065bda73ADc7E9337b64F/logo.png" alt="Only1Token" style={{ width: "24px", height: "24px", borderRadius: "18px", marginRight: "10px" }} /> Only1Token</MenuItem>
                  <MenuItem value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"><img src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png" alt="Wrapped USD" style={{ width: "24px", height: "24px", borderRadius: "18px", marginRight: "10px" }} /> Wrapped USD</MenuItem>
                  <MenuItem value="0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"><img src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png" alt="Wrapped BTC" style={{ width: "24px", height: "24px", borderRadius: "18px", marginRight: "10px" }} /> Wrapped BTC</MenuItem>
                  <MenuItem value="0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"><img src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png" alt="CAKE" style={{ width: "24px", height: "24px", borderRadius: "18px", marginRight: "10px" }} /> CAKE</MenuItem>
                </Select>
              </FormControl>
              <p>List your token here! <a href="https://t.me/admonkeytoken" target="_blank">Ask us!</a></p>
            </form>
            {currentToken === "BNB" ? null : (
              <Button variant="outlined" color="secondary" fullWidth onClick={handleRevertToBnb} style={{ marginTop: 10 }}>Revert to BNB</Button>
            )}
          </Paper>
          <Paper style={{ padding: 20, marginTop: 20 }}>
            <Transactions transactions={transactions} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9} style={{ padding: 20 }}>
          <Paper style={{ padding: 20, marginTop: 10, marginBottom: 20, backgroundColor: "#fcc400", borderColor: "#1b1b1b" }} variant="outlined">
            <Typography variant="h5">Your Dividends</Typography>
            <Grid item xs={12}>
              <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                <Typography component="h6" variant="h6">Your Holdings:</Typography>
                <Typography component="p" variant="body1">{balanceV2} AM</Typography>
              </Paper>
            </Grid>
            <Grid container xs={12} style={{ paddingTop: 20, paddingBottom: 20 }} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Pending Dividends</Typography>
                  <Chip label={withdrawable} variant="outlined" style={{ fontSize: "18px" }} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Total Dividends</Typography>
                  <Chip label={dividendTotal} variant="outlined" style={{ fontSize: "18px" }} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Queue Position</Typography>
                  <Chip label={dividendQueue} variant="outlined" style={{ fontSize: "18px" }} />
                </Paper>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ padding: 20, marginTop: 10, marginBottom: 20, backgroundColor: "#1b1b1b", borderColor: "#fcc400", color: "white" }} variant="outlined">
            <Typography variant="h5">Faucet</Typography>
            <Grid container xs={12} style={{ paddingTop: 20, paddingBottom: 20 }} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Current BNB Balance</Typography>
                  <Chip label={balanceBnb} style={{ fontSize: "16px" }} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Withdrawable</Typography>
                  <Chip label={dividendWithdrawable} style={{ fontSize: "16px" }} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Last Dividend Paid</Typography>
                  {dividendLastClaimTime <= 1630450800 ? null : (

                    <Chip label={format(dividendLastClaimTime * 1000, "do MMM yyyy")} style={{ fontSize: "16px" }} />

                    )}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                {dividendWithdrawable <= 0 ? null : (
                  <WithdrawButton withdrawRewards={withdrawRewards} />
                )}
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ padding: 20, marginTop: 10, marginBottom: 10, backgroundColor: "#fcc400", color: "black" }} variant="outlined">
            <Typography variant="h5">Tax Free BuyBack</Typography>
            <Grid container xs={12} style={{ paddingTop: 20, paddingBottom: 20 }} justifyContent="space-between" alignItems="stretch">
              <Grid item xs={12} sm={6}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                  <Typography variant="h6" component="h6">Available BuyBack Amount</Typography>
                  <Chip label={AlteredTaxFreeAmount} style={{ fontSize: "16px" }} onClick={() => populateBuybackInput(taxFreeAmount)} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper style={{ padding: 20, margin: 5 }} elevation={3}>
                <form onSubmit={handleSubmitBnbBuyback}>
                  <FormControl fullWidth>
                  <input
                    type="number"
                    id="standard-full-width"
                    placeholder="Enter BNB Amount"
                    helperText="Enter BNB buyback amount"
                    name="buybackAmount"
                    fullWidth
                    readOnly
                    value={sliderInputValue}
                  />
                    <Slider
                    min={0}
                    max={100}
                    step={1}
                    defaultValue={50}
                    onChange={handleSliderChange}
                    />
                    <IconButton type="submit" size="small" display="inline"><SendIcon style={{ marginRight: 3 }} />BUYBACK</IconButton>
                  </FormControl>
                </form>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={9} style={{ padding: 20 }}>
          
        </Grid>
      </Grid>

    </Container>
  );
}

