import React, { useState, useEffect } from "react";
import useWeb3Modal from "../../hooks/useWeb3Modal";
import Dashboard from "./rewardsDashboard/Dashboard";
import Lof from "../../contracts/LOF";
import LofV2 from "../../contracts/LOFV2";
import Migration from "../../contracts/Migration";
import { getTokenTransactionsForWallet } from "../../services/bscscan";
import { getTokenInfoForAddress} from "../../services/bscscan";
import BigNumber from "bignumber.js";

export default function RewardsContainer() {

  const [initialized, setInitialized] = useState();
  const [provider, setProvider] = useState();
  const [balance, setBalance] = useState();
  const [LOF, setLOF] = useState();
  const [contractV1Address, setContractV1Address] = useState();
  const [contractV2Address, setContractV2Address] = useState();
  const [LOFV2, setLOFV2] = useState();
  const [migration, setMigration] = useState();
  const [invalidChain, setInvalidChain] = useState();
  const [balanceV2, setBalanceV2] = useState();
  const [balanceMigration,setBalanceMigration] = useState();
  const [loading, setLoading] = useState(true);
  const [chainId, setChainId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [swapTokensTransactionId, setSwapTokensTransactionId] = useState(null);
  const [hasMigratedTokens, setHasMigratedTokens] = useState(null);
  const [migrationPending, setMigrationPending] = useState(false);
  const [migrationMessage, setMigrationMessage] = useState(null);
  const [migrationComplete, setMigrationComplete] = useState(false);
  const [currentToken, setCurrentToken] = useState([]);
  const [currentTokenAddress, setCurrentTokenAddress] = useState();

  let migrationToContinue = false;

  const [_, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal({
    setChainId: (chainId) => {
      setChainId(chainId);
    },
    setInvalidChain: (isInvalid) => {
      setInitialized(true);
      setInvalidChain(isInvalid);
    },
    onLoad: (provider, selectedAccount, bnbBalance) => {
      setInitialized(true);
      setInvalidChain(false);
      setProvider(provider);
      loadLOF(provider, selectedAccount);
      loadLOFV2(provider, selectedAccount);
      loadMigration(provider,selectedAccount);
      setSelectedAddress(selectedAccount);
      setLoading(false);
    },
  });

  const loadLOF = async (provider, account) => {
    setChainId(JSON.stringify(account));
    if (provider) {
      const LOFContract = new Lof(provider.provider, account);
      await setLOF(LOFContract);
      await setContractV1Address(LOFContract.contract._address);

      const balance = await LOFContract.getBalance();
  
      setBalance(parseFloat(balance / 10 ** 9).toFixed(9));

      console.log(`V1 balance is: ${balance}`);
    }
  };

  const loadLOFV2 = async (provider, account) => {
    setChainId(JSON.stringify(account));
    if (provider) {
      const LOFContractV2 = new LofV2(provider.provider, account);
      
      await setLOFV2(LOFContractV2);
      await setContractV2Address(LOFContractV2.contract._address);

      const balanceV2 = await LOFContractV2.getBalance();

      let balance = await provider.getBalance(account);

      if(balanceV2 > 0 && balance <= 0){
        setMigrationComplete(true);
      }

      setBalanceV2(parseFloat(balanceV2 / 10 ** 9).toFixed(9));
     }
  };

  const loadMigration = async (provider, account) => {
    setChainId(JSON.stringify(account));
    if (provider) {

      const migrationContract = new Migration(provider.provider, account);
      await setMigration(migrationContract);

    }
  }

  const onSwapTokens = async (provider, account) => {
    if(migration) {

      migrationToContinue = true;

      setMigrationPending(true);
      setMigrationMessage("Connecting to the blockchain...");

      setTimeout(setMigrationMessage("Still waiting for the blockchain..."), 2000);

      const decimals = await LOF.contract.methods.decimals().call(); // get decimals from contract
      const balance = await LOF.contract.methods.balanceOf(selectedAddress).call(); // get balance of msg.sender (user) from contract

      console.log(`Migration balance: ${balance}`);

      const v = getDecimalAndInt(+balance);  // To get the count of numbers after decimal point.
      //const value = LOF.provider.utils.toBN(v.integer).mul(LOF.provider.utils.toBN(10 ** +(decimals - v.decimals))); // handle the big number
      const value = balance;

      await LOF.contract.methods
        .approve(
          process.env.REACT_APP_MIGRATION_ADDRESS, value)
        .send({
          from: selectedAddress })
        .catch(() => {
          migrationToContinue = false;
          return;
        });

      console.log(`Migration balance: ${value}. Migration status: ${migrationToContinue}`);

      setMigrationMessage("Received approval of V1 token transfer...");

      if(migrationToContinue){
        await migration.contract.methods.transferTokens().send({from: selectedAddress})
        .on('transactionHash', function(hash){
          console.log(hash);
          
          setSwapTokensTransactionId(hash);
          setHasMigratedTokens(hash);

          setMigrationMessage(`Received transaction hash from blockchain: #${hash}...`);

        })
        .catch(() => {
          setMigrationPending(false);
          return;
        });

        const updatedBalance = await LOF.contract.methods.balanceOf(selectedAddress).call();
        const updatedBalanceV2 = await LOFV2.contract.methods.balanceOf(selectedAddress).call();

        setMigrationMessage("Updating balances...");
        
        setBalance(updatedBalance/10**9);
        setBalanceV2(updatedBalanceV2/10**9);

        setMigrationMessage("Migration complete...!");

        migrationToContinue = false;
        setMigrationPending(false);
        setMigrationComplete(true);
      }

      
      
    }
    return false;
  }

  const getDecimalAndInt = (balance) => {
    balance = `${balance}`;
    if (!balance.includes('.')) {
      return {
        decimals: 9,
        integer: balance
      };
    }
    return {
      decimals: balance.length - balance.indexOf('.') - 1,
      integer: balance.replace('.', '')
    }
  }

  return (
    <div>
      <Dashboard
        initialized={initialized}
        balance={balance}
        balanceV2={balanceV2}
        balanceMigration={balanceMigration}
        invalidChain={invalidChain}
        provider={provider}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        loading={loading}
        selectedAddress={selectedAddress}
        onSwapTokens={onSwapTokens}
        swapTokensTransactionId={swapTokensTransactionId}
        contractV1={contractV1Address}
        contractV2={contractV2Address}
        hasMigratedTokens={hasMigratedTokens}
        migrationPending={migrationPending}
        migrationMessage={migrationMessage}
        migrationComplete={migrationComplete}
      />
    </div>
  );
}
