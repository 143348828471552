import React, { Component } from 'react';
import logo from '../logo.png';
import Routes from "./Routes";
import AppContext from "../services/app-context";
import Main from './Main'
import '../assets/App.css';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  migrateTokens() {
    this.setState({ loading: false })
  }

  render() {
    return (
      <AppContext.Provider value={this.state.appContext}>
        <Routes />
      </AppContext.Provider>
    );
  }
}

export default App;
