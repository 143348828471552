import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "50%",
    padding: "1 rem",
  },
  centered: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export default function Balance({ balance, contract, contractAddress }) {
  const classes = useStyles();

  return (
    <div style={{ padding: "40px" }}>
      <div className={classes.centered}>
        <Typography
          variant="h5"
          component="h6"
          style={{
            marginBottom: "15px",
            fontWeight: "100",
            color: "white",
          }}
        >
          <span
            style={{ fontSize: "3.2rem", display: "block", color: "white" }}
          >
            {contract}
          </span>{" "}
          Balance
        </Typography>
      </div>
      <Typography
        component="p"
        variant="h5"
        className={classes.centered}
        style={{ fontSize: "32px", marginBottom: "20px", color: "white" }}
      >
        {`${balance ? balance : 0}`}
      </Typography>
      <Typography
        component="p"
        style={{ fontSize: "16px", fontWeight: "200", color: "white" }}
      >
        {contract} address:
      </Typography>
      <Chip
        variant="outlined"
        color="white"
        size="small"
        icon={<DescriptionIcon />}
        label={contractAddress}
        onClick={() => {
          navigator.clipboard.writeText(contractAddress);
        }}
      />
    </div>
  );
}

Balance.propTypes = {
  balance: PropTypes.number,
};
