import React from "react";
import Button from "@material-ui/core/Button";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Migration from "../../../../contracts/Migration";

export default function SwapTokensButton({ balance, onSwapTokens }) {
  return (
    <Button
      style={{
        backgroundColor: "rgba(217, 171, 249, 0.4)",
        color: "white",
        borderRadius: "36px",
        fontSize: "24px",
        padding: "10px 40px",
        fontWeight: "600",
        marginTop: "40px",
      }}
      size="large"
      onClick={() => {
        onSwapTokens();
      }}
    >
      {balance == 0 ? "Not Available" : "Swap Tokens"}
    </Button>
  );
}
