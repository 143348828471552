import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import WalletButton from "./buttons/WalletButton";
import Logo from "../../../assets/lof_logo_black.png";
import ConnectToWallet from "./ConnectToWallet";
import Balance from "./Balance";
import Card from "@material-ui/core/Card";
import { ThreeDRotation } from "@material-ui/icons";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SwapTokensButton from "./buttons/SwapTokensButton";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import DAppV2 from "./dAppV2";
import Backdrop from "@material-ui/core/Backdrop";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Arrow from "../../../assets/sansarrow.png";
import ArrowD from "../../../assets/sansarrowD.png";
function Copyright() {
  return (
    <div style={{ textAlign: "right", color: "#d8aaf8" }}>
      <p>
        ©{" "}
        <a
          href="https://lonelyfans.io"
          style={{ color: "#d8aaf8", textDecoration: "none" }}
        >
          LOFCRYPTO
        </a>{" "}
        {new Date().getFullYear()}
      </p>
    </div>
    // <Typography variant="body2" color="common.white" align="right">
    //   {"©"}
    //   <Link color="inherit" href="https://lofcrypto.com">
    //     LOFCRYPTO
    //   </Link>{" "}
    //   {new Date().getFullYear()}
    //   {"."}
    // </Typography>
  );
}

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      light: "00D1EF",
      main: "#1b1b1b",
      dark: "#ffc20d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffc20d",
      main: "#f44336",
      dark: "#ffc20d",
      contrastText: "#000",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flexbox",
    paddingBottom: 20,
    width: "100%",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(117, 112, 153, 0.4)",

    position: "relative",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logo: {
    maxWidth: "60px",
    width: "100%",
    marginRight: "10px",
    padding: "0",
  },
  arrow: {},
  arrowDown: {
    transform: "rotate(90deg)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 10,
    color: "black",
    display: "flex !important",
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "white",
    fontFamily: "Rubik",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: "70%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 300,
  },
  dashTitle: {
    fontFamily: "Rubik",
    fontSize: "2.1rem",
    color: "black",
    marginTop: "40px",
    textAlign: "center",
  },
  gridTable: {
    flexGrow: 1,
  },
  logoMain: {
    display: "block",
    maxWidth: "120px",
    textAlign: "center",
    margin: "5px auto 5px auto",
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const {
    initialized,
    balance,
    balanceV2,
    balanceMigration,
    invalidChain,
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    loading,
    selectedAddress,
    onSwapTokens,
    swapTokenTransactionId,
    contractV1,
    contractV2,
    hasMigratedTokens,
    migrationPending,
    migrationMessage,
    migrationComplete,
  } = props;

  const [open, setOpen] = useState(migrationPending);
  const [initialising, setInitialising] = useState(loading);

  const [isChecked, setIsChecked] = useState(migrationComplete ? true : false);

  let rfiReward = 0;

  const balanceToExchange = balance - balanceV2;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar} position="relative">
        <Toolbar className={classes.toolbar}>
          <img src={Logo} className={clsx(classes.logo)} />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          ></Typography>
          <WalletButton
            provider={provider}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            selectedAddress={selectedAddress}
          />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div style={{ textAlign: "center", padding: "20" }}>
          {!migrationPending ? null : (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                verticalAlign: "middle",
                textAlign: "center",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "1000001",
                backgroundColor: "rgba(60,60,60,0.8)",
              }}
            >
              <CircularProgress style={{ width: "120px", height: "120px" }} />
              <Typography
                component="p"
                style={{
                  color: "white",
                  display: "block",
                  marginTop: "20px",
                  fontSize: "28px",
                  fontWeight: "200",
                }}
              >
                {migrationMessage}
              </Typography>
            </div>
          )}
          <Container className={classes.container}>
            <img src={Logo} className={clsx(classes.logoMain)} />
            {/* <Typography component="h1" variant="h5" style={{ marginBottom: 20 }}>Welcome to the LOF Migration Portal</Typography> */}
            <div className="font-face-gm" style={{ color: "#d8aaf8" }}>
              Welcome to the LOF Migration Portal
            </div>
            <Box component="div" spacing={4} style={{ marginTop: "20px" }}>
              {!hasMigratedTokens ? null : (
                <div
                  style={{
                    backgroundColor: "#ffc20d",
                    color: "black",
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Rubik",
                    fontWeight: "200",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ fontSize: "1.5rem" }}>
                    Congratulations! You have migrated your tokens. Track it on:{" "}
                    <a
                      href={`${process.env.REACT_APP_BSCSCAN_BASE_URL}/tx/${hasMigratedTokens}`}
                      target="_blank"
                    >
                      BSCSCAN.
                    </a>
                  </div>
                </div>
              )}
              <Grid container className={classes.gridTable}>
                <Grid
                  item
                  sm={5}
                  xs={12}
                  style={{ textAlign: "center", color: "white" }}
                >
                  <Card
                    variant="outlined"
                    style={{ backgroundColor: "rgba(108, 107, 141, 0.4)" }}
                  >
                    <Balance
                      balance={balance}
                      contract="V1"
                      contractAddress={contractV1}
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={12}
                  style={{
                    color: "white",
                    textAlign: "center",
                    verticalAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Hidden smDown>
                    <img src={Arrow} className={clsx(classes.arrow)} />
                  </Hidden>
                  <Hidden mdUp>
                    <img src={ArrowD} className={clsx(classes.arrowDown)} />
                  </Hidden>
                </Grid>
                <Grid item sm={5} xs={12} style={{ textAlign: "center" }}>
                  <Card
                    variant="outlined"
                    style={{ backgroundColor: "rgba(108, 107, 141, 0.4)" }}
                  >
                    <Balance
                      balance={balanceV2}
                      contract="V2"
                      contractAddress={contractV2}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid item sm={12} style={{ textAlign: "center" }}>
                <div>
                  <Typography
                    component="p"
                    color="black"
                    noWrap
                    style={{ marginTop: "20px", color: "white" }}
                  >
                    Connected Wallet:
                  </Typography>
                  <Chip label={selectedAddress} />
                </div>
                <div>
                  <Typography
                    component="p"
                    color="black"
                    noWrap
                    style={{ marginTop: "20px", color: "white" }}
                  >
                    Balance to migrate:
                  </Typography>
                  <Chip label={balanceToExchange} />
                </div>
              </Grid>
              <Grid item sm={12} style={{ textAlign: "center" }}>
                <SwapTokensButton
                  balance={balance}
                  onSwapTokens={onSwapTokens}
                  onClick={handleClickOpen}
                />
              </Grid>
            </Box>

            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </main>
    </div>
  );
}
