import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Title from "./Title";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from "prop-types";
import { format } from "date-fns";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Divider from '@material-ui/core/Divider';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Transactions(props) {
  const classes = useStyles();


  const transactions = props.transactions ? props.transactions : [];
  return (
    <React.Fragment>
      <Typography variant="h5" component="h5" style={{ color: "black" }}>Recent Transactions</Typography>
        <List>
          {transactions.map((row) => (
            <ListItem key={row.hash}>
              <ListItemIcon>
                {row.direction === "buy" ? <AddCircleOutlineIcon style={{ color: "green" }} /> : <RemoveCircleOutlineIcon style={{ color: "red" }} /> }
              </ListItemIcon>
              <ListItemText primary={format(row.timeStamp * 1000, "do MMM yyyy")} secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {Math.round((row.value / 10 ** 9) * 1000, 6) / 1000}
                </Typography>
                <a href={`${process.env.REACT_APP_BSCSCAN_BASE_URL}/tx/${row.hash}`} style={{ marginLeft: 10 }} target="_blank">BSCScan <OpenInNewIcon fontSize="small" /></a>
              </React.Fragment>
              } />
              
            </ListItem>
          ))}
        </List>
    </React.Fragment>
  );
}

Transactions.propTypes = {
  transactions: PropTypes.array,
};
